import * as React from 'react'
import loadable from '@loadable/component'
import PropTypes from 'prop-types'
import { useFlexSearch } from 'react-use-flexsearch'

const SearchResultsContainer = loadable(() =>
    import('/src/components/Search/SearchResults/SearchResultsContainer')
)
const SearchResultsPostList = loadable(() =>
    import('/src/components/Search/SearchResults/SearchResultsPostList')
)
const SearchResultsPageList = loadable(() =>
    import('/src/components/Search/SearchResults/SearchResultsPageList')
)
const ZeroSearchResults = loadable(() =>
    import('/src/components/Search/SearchResults/ZeroSearchResults')
)

const SearchResults = ({ postType, postTypeTitle, searchData, searchTerm }) => {
    const results = useFlexSearch(
        searchTerm,
        searchData.index,
        searchData.store
    )
    const posts = results.filter((node) => node.type === postType) // Filter the array to only include posts of a certain type
    const pages = results.filter(
        (node) => node.type === 'page' && node.robotsStatus === 'index'
    ) // Filter the array to only include the pages

    const postsTitle = `Found <span>${posts.length}</span> ${postTypeTitle} ${
        posts.length === 1 ? 'result' : 'results'
    } for <span>${searchTerm}</span>:`
    const pagesTitle = `We found <span>${pages.length}</span> other ${
        pages.length === 1 ? 'result' : 'results'
    } for <span>${searchTerm}</span>:`

    return (
        <>
            {posts.length > 0 ? (
                <SearchResultsContainer size={'narrow'} title={postsTitle}>
                    <SearchResultsPostList
                        type={'search'}
                        postsPerPage={6}
                        searchTerm={searchTerm}
                        itemsRepeater={posts}
                    />
                </SearchResultsContainer>
            ) : (
                <ZeroSearchResults
                    searchTerm={searchTerm}
                    title={postTypeTitle}
                />
            )}

            {pages.length > 0 && (
                <SearchResultsContainer
                    containerVariant={'alt'}
                    size={'full'}
                    title={pagesTitle}
                >
                    <SearchResultsPageList
                        variant={'columns'}
                        itemsRepeater={pages}
                    />
                </SearchResultsContainer>
            )}
        </>
    )
}

SearchResults.propTypes = {
    postType: PropTypes.string.isRequired,
    postTypeTitle: PropTypes.string.isRequired,
    searchData: PropTypes.object.isRequired,
    searchTerm: PropTypes.string.isRequired
}

SearchResults.defaultProps = {
    postType: 'post',
    postTypeTitle: 'Post Type Title'
}

export default SearchResults
